<template>
<div class="minGame" :class="{'minGame-phone':isMobileFlag}" v-if="gameVisible">
    <iframe style="--h: 0px" ref="gameIframeRef" class="minGame-iframe" :src="gameUrl" frameborder="0"></iframe>
</div>
</template>
  
<script>
import { game_login_game_api, try_game_api } from "@/api/cybSports";
import { mapState } from "vuex";

export default {
    props: {
        gid: {
            default: 0
        }, // 5384
    },
    computed: {
        ...mapState({
            isLoginStatu: (state) => state.isLoginStatu,
        }),
    },
    data() {
        return {
            gameUrl: null,
            loadGame: false,
            gameVisible: false,
            iframeHeight: 0
        };
    },
    methods: {
    // 获取游戏路径
        async getGameGetUrl(gid) {
            if (this.loadGame) {
                return;
            }
            this.loadGame = true;
            let platform = this.isMobileFlag ? 2 : 1;
            await game_login_game_api({ platform, gid }).then((res) => {
                this.loadGame = false;
                if (res && res.data.code == 1) {
                    this.gameVisible = true;
                    this.gameUrl = res.data.data + "&min=true";
                } else {
                    // this.errorTips(res.data.msg)
                }
            });
        },
        async getTryGameUrl(gid) {
            if (this.loadGame) {
                return;
            }
            this.loadGame = true;
            let platform = this.isMobileFlag ? 2 : 1;
            const params = { platform, game_id: gid };
            await try_game_api(params).then((res) => {
                this.loadGame = false;
                if (res && res.data.code == 1) {
                    this.gameVisible = true;
                    const gameUrl = res.data.data.game_url;
                    this.gameUrl = gameUrl + "&min=true";
                } else {
                    // this.errorTips(res.data.msg)
                }
            });
        },

        getGameUrl() {
            if (this.isLoginStatu) {
                this.getGameGetUrl(this.gid);
            } else {
                this.getTryGameUrl(this.gid);
            }
        }
    },
    mounted() {
        if (!this.gid) {
            return;
        }
        this.getGameUrl();
        var _this = this;
        window.addEventListener('message', (event) => {
            console.log(event.data)
            const action = event.data.action || '';
            switch (action) {
                case 'setHeight':
                    if (_this.$refs.gameIframeRef) {
                        _this.$refs.gameIframeRef.style.setProperty('--h', event.data.height)
                        _this.$refs.gameIframeRef.contentWindow.postMessage(['circle', '#0d131c'], '*');
                    }
                    break;
                case 'fullScreen':
                    _this.$router.push({ path: '/game-detail', query: { id: this.gid } });
                    break;
                case 'Login':
                    this.showLoginReg(1);
                    break;
            }
        });
    },
    watch: {
        gid(newVal) {
            if (newVal) {
                this.getGameUrl();
            }
        },
        isLoginStatu(newVal) {
            this.getGameUrl();
        }
    }
};
</script>

<style scoped lang="less">
.minGame {
    width: 100%;
    &.minGame-phone {
        width: 100%;
    }
    .minGame-iframe {
        width: 100%;
        height: var(--h);
    }
}
</style>
  